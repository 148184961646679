import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Collapse from 'react-bootstrap/Collapse'
import ExperienceCard from '../ExperienceCard/ExperienceCard'
import JosephNavbar from '../Navbar/JosephNavbar'
import Project from '../Project/Project'
import './stylesheet.css'
import Projects from '../../../Data/Joseph/projects.json'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import { useState } from 'react'
import Text from '../../../Data/Joseph/text.json'
import Experience from '../../../Data/Joseph/experience.json'
import GitHubCalendar from 'react-github-calendar'
import { useEffect } from 'react'
import { useRef } from 'react'
import axios from 'axios'

const JosephContent = () => {
    const [contactName, setName] = useState('');
    const [contactEmail, setEmail] = useState('');
    const [contactMessage, setMessage] = useState('');

    const [open1, setOpen1] = useState(true);
    const [open2, setOpen2] = useState(true);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    let theme = {
                "level0" : "rgba(189, 41, 10, 0.1)",
                "level1" : "rgba(189, 41, 10, 0.4)",
                "level2" : "rgba(189, 41, 10, 0.6)",
                "level3" : "rgba(189, 41, 10, 0.8)",
                "level4" : "rgba(189, 41, 10, 1.0)"
            }
    function spinArrow(ev) {
        var state 
        if (ev.target.nodeName === 'H1') {
            state = ev.target.ariaExpanded
            if (ev.target && ev.target.lastChild && ev.target.lastChild.firstChild) {            
                if (state === 'true') {
                    ev.target.lastChild.firstChild.classList.add('down')
                } else {
                    ev.target.lastChild.firstChild.classList.remove('down')
                }
            }  
        } else if (ev.target.nodeName === 'I') {
            state = ev.target.parentNode.ariaExpanded
            if (ev.target.parentNode && ev.target && ev.target.firstChild) {            
                if (state === 'true') {
                    ev.target.firstChild.classList.add('down')
                } else {
                    ev.target.firstChild.classList.remove('down')
                }
            }
        }
        
    }
    let contactForm = useRef()
    let field1 = useRef()
    let field2 = useRef()
    let field3 = useRef()

    function postToGoogle(e) {
        e.preventDefault();
        var endpoint = 'https://docs.google.com/forms/u/0/d/e/1FAIpQLSfRvKlSBPnBWh-AzUhJ6Vzhn-hzCEuue4SB1W31SN1jz-_23g/formResponse'
        var config = {
                        headers: {
                                'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        params: {
                                'entry.1073141020': contactName,
                                'entry.406781218': contactEmail,
                                'entry.2119962547': contactMessage
                        }
            };
        axios.post( endpoint, {}, config)
        .then(res => {
            console.log(res)
            contactForm.current.reset()
        })
        .catch(err => {
            console.log(err)
            contactForm.current.reset()
        })

    }

    var projectsArray = []
    var workArray = []
    var educationArray = []
    var volunteerArray = []
    var extracurricularArray = []
    function setUpContent(){
        var i = 0
        var element
        for (const key in Projects) {
            if (Projects.hasOwnProperty(key)) {
                element = <Project key={i} nameRef={key}></Project>
                projectsArray.push(element)
                i+=1
            }
        }
        for (const key in Experience.work) {
            if (Experience.work.hasOwnProperty(key)) {
                element = <ExperienceCard key = {i} nameRef={key} typeRef="work"> </ExperienceCard>
                workArray.push(element)
                i+=1
            }
        }
        for (const key in Experience.education) {
            if (Experience.education.hasOwnProperty(key)) {
                element = <ExperienceCard key = {i} nameRef={key} typeRef="education"> </ExperienceCard>
                educationArray.push(element)
                i+=1
            }
        }
        for (const key in Experience.volunteer) {
            if (Experience.volunteer.hasOwnProperty(key)) {
                element = <ExperienceCard key = {i} nameRef={key} typeRef="volunteer"> </ExperienceCard>
                volunteerArray.push(element)
                i+=1
            }
        }
        for (const key in Experience.extracurricular) {
            if (Experience.extracurricular.hasOwnProperty(key)) {
                element = <ExperienceCard key = {i} nameRef={key} typeRef="extracurricular"> </ExperienceCard>
                extracurricularArray.push(element)
                i+=1
            }
        }
    }
    setUpContent()

    return (
        <div className='JosephContent'>
            <JosephNavbar buttons={['About', 'Projects', 'Contact']}></JosephNavbar>
            <div className="main_container">
                <Container fluid className="contentCard">
                    <h1 id="about">About</h1>
                        <p>
                            {Text.about}
                        </p>
                </Container>
                <Container fluid  className="contentCard">
                    <h1 onClick={(ev) => {
                        setOpen1(!open1)
                        spinArrow(ev)
                    }}
                        aria-controls="example-collapse-text"
                        aria-expanded={open1}>Experience <i><img className="arrowIcon" src="./images/arrowicon.png" alt="" /></i></h1>
                    <Collapse in={open1}>
                    <Container fluid className="flexContainer">
                        {workArray}
                    </Container>
                    </Collapse>
                </Container>
                <Container fluid className="contentCard">
                    <h1 onClick={(ev) => {
                        setOpen2(!open2)
                        spinArrow(ev)
                    }}
                        aria-controls="example-collapse-text"
                        aria-expanded={open2}>Education <i><img className="arrowIcon" src="./images/arrowicon.png" alt="" /></i></h1>
                    <Collapse in={open2}>
                    <Container fluid className="flexContainer"> 
                        {educationArray}
                        </Container>
                        </Collapse>
                </Container>
                <Container fluid className="contentCard">
                    <h1 onClick={(ev) => {
                        setOpen3(!open3)
                        spinArrow(ev)
                    }}
                        aria-controls="example-collapse-text"
                        aria-expanded={open3}>Volunteer Experience <i><img className="arrowIcon down" src="./images/arrowicon.png" alt="" /></i></h1>
                    <Collapse in={open3}>
                    <Container fluid className="flexContainer">
                        {volunteerArray}
                    </Container>
                    </Collapse>
                </Container>
                <Container fluid className="contentCard">
                    <h1 onClick={(ev) => {
                        setOpen4(!open4)
                        spinArrow(ev)
                    }}
                        aria-controls="example-collapse-text"
                        aria-expanded={open4}>Extracurricular <i><img className="arrowIcon down" src="./images/arrowicon.png" alt="" /></i></h1>
                    <Collapse in={open4}>
                    <Container fluid className="flexContainer">
                        {extracurricularArray}
                    </Container>
                    </Collapse>
                </Container>
                <Container fluid className="contentCard">
                    <h1 id="projects">Projects</h1>
                    <Container fluid className="ProjectsBox">
                        {projectsArray}
                    </Container>
                </Container>
                <Container fluid className="contentCard">
                    <h1 id="contact">Contact</h1>
                    <Container >
                        <Row className="contactRow">
                        <Col className="contactForm" sm={9}>
                            <Form ref={contactForm} id="contact" className="d-grid" name="contact" method="post" onSubmit={postToGoogle}>
                                <input type="hidden" name="form-name" value="contact" />
                                <Form.Group className="mb-3" controlId="name">
                                    <FloatingLabel
                                        controlId="Name"
                                        label="Name"
                                        className="mb-3"
                                        ref={field1}
                                    >
                                        <Form.Control onChange={e => setName(e.target.value)}  value={contactName} name="entry.283551948" type="text" placeholder="Enter your name" required={true} />
                                    </FloatingLabel>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="email">
                                    <FloatingLabel
                                        controlId="Email"
                                        label="Email"
                                        className="mb-3"
                                        ref={field2}
                                    >
                                        <Form.Control onChange={e => setEmail(e.target.value)} value={contactEmail} name="entry.406781218" type="email" placeholder="Enter your email" required={true} />
                                    </FloatingLabel>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="message">
                                    <FloatingLabel
                                        controlId="Message"
                                        label="Message"
                                        className="mb-3"
                                        ref={field3}
                                    >
                                        <Form.Control onChange={e => setMessage(e.target.value)} value={contactMessage} name="entry.2119962547" as="textarea" placeholder="" />
                                    </FloatingLabel>
                                </Form.Group>

                                
                                <Button size="lg" variant="primary" type="submit">
                                    Submit
                                </Button>
                            </Form>
                        </Col>
                        <Col className="qrCol" sm={1}>
                        <img className="qrCode" src="./images/joseph/frameRED.png" alt="" />
                        </Col>
                    </Row>
                    <Container className="centerContent">
                        <GitHubCalendar username="jc1775" theme={theme}/>
                    </Container>
                    
                    </Container>
                </Container>
        
            </div>
        </div>
    )
}

export default JosephContent