import './JosephNavbar.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import React, { useEffect } from 'react';

const JosephNavbar = (props) => {
    let buttons = props.buttons
    var buttonsHTML = []
    createButtons()
    function createButtons(){
        var i = 0
        buttons.forEach(buttonName => {
            var lowercaseName = buttonName.toLowerCase()
            buttonsHTML.push(<Col key={i}><button onClick={scrollToSection} data-scrollto={lowercaseName} name={lowercaseName + 'BTN'} className="item">{buttonName}</button></Col>)
            i+=1
        });
    }

    function scrollToSection(ev){
        var navButtons = document.getElementsByClassName("item")
        var elementToScroll = document.getElementById(ev.target.getAttribute("data-scrollto"))
        if (elementToScroll) {
            var yOffset = 0
            var y = elementToScroll.getBoundingClientRect().top + window.pageYOffset + yOffset
            window.scrollTo({top:y, behavior: 'smooth'})
            
            Array.from(navButtons).forEach(btn => {
                btn.classList.remove("active")
            });
            ev.target.classList.add("active")
        }    
    }

    function toggleScrollbar(ev){
        var targetElement = ev.target
        var menu = document.getElementById('menu')
        var expanded = targetElement.getAttribute("data-expanded");
        if (expanded === 'true') {
            targetElement.setAttribute("data-expanded","false")
            menu.classList.add("minimized")
            showSectionTitle(true)
        } else {
            targetElement.setAttribute("data-expanded","true")
            menu.classList.remove("minimized")
            showSectionTitle(false)
        }
    }

    function showSectionTitle(show){
        var sectionTitle = document.getElementById('section_title')
        if (show) {
            sectionTitle.classList.add('active')
        } else {
            sectionTitle.classList.remove('active')
        }
    }

    useEffect(() => {
        var contentCards = document.getElementsByClassName('contentCard')        
        var SCROLL_POSITION = window.scrollY
        function cardIsInView(ev){
            for (let index = 0; index < Array.from(contentCards).length; index++) {
                var element = Array.from(contentCards)[index];
                var rect = element.getBoundingClientRect()
                var elementHeight = element.firstChild.getBoundingClientRect().height
                if (elementHeight > rect.top && rect.top > - elementHeight && (element.firstChild.ariaExpanded === 'true' || !element.firstChild.ariaExpanded)) {
                    element.firstChild.classList.add('inactive')
                    return element
                } else {
                    element.firstChild.classList.remove('inactive')
                }
            }


        }
        function navBarScrollChecker() {
            var targetElement = document.getElementById('menu_btn')
            if (targetElement) {
                var expanded = targetElement.getAttribute("data-expanded");
                if (SCROLL_POSITION > window.scrollY) {
                    if (expanded === 'false') {
                        document.getElementById('menu_btn').setAttribute("data-expanded","true")
                        document.getElementById('menu').classList.remove("minimized")
                        showSectionTitle(false)
                    }
                } else {
                    if (expanded === 'true') {
                        document.getElementById('menu_btn').setAttribute("data-expanded","false")
                        document.getElementById('menu').classList.add("minimized")
                        showSectionTitle(true)
                    }
                    
                }
            }
        }

        var sectionTitleText = document.getElementById('section_title_text')
        function scrollChecks() {
            navBarScrollChecker()
            var focusedCard = cardIsInView()
            if (focusedCard) {
                sectionTitleText.innerText = focusedCard.firstChild.innerText
                var navBTN = document.getElementsByName(focusedCard.firstChild.id + 'BTN')
                if (navBTN[0]) {
                    var navButtons = document.getElementsByClassName("item")
                    Array.from(navButtons).forEach(btn => {
                        btn.classList.remove("active")
                    });
                    navBTN[0].classList.add("active")
                }
            }
            SCROLL_POSITION = window.scrollY
        }
        window.addEventListener("scroll", scrollChecks)

    }, []);

    return (
        <div className='JosephNavbar'>
        <div className="menu_container">
            <div id='menu_btn' onClick={toggleScrollbar} className="menubtn" data-expanded="true"></div>
            <div id='section_title'>
                <h1 id='section_title_text'></h1>
            </div>
            <div id="menu" className="menu">
                <Container fluid style={{height: '100%'}}>
                    <Row style={{height: '100%'}}>
                        {buttonsHTML}
                    </Row>
                    
                </Container>
                
            </div>
        </div>
        </div> 
    )
}

export default JosephNavbar