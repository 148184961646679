import './JosephFooter.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Socials from '../../../Data/Joseph/socials.json'
import Social from '../Social/Social'

const JosephFooter = () => {
    var socialArray = []
    var i = 0
    for (const key in Socials) {
        if (Socials.hasOwnProperty(key)) {
            var element = <Social key={i} nameRef={key}></Social>
            socialArray.push(element)
            i+=1
        }
    }

    return (
        <div className="JosephFooter">
            {socialArray}
        </div>
    )
}

export default JosephFooter