import './App.css';
import JosephHome from './Components/Joseph/Home/JosephHome';
import ProjectPage from './Components/Joseph/ProjectPage/ProjectPage';
import { BrowserRouter as Router , Route, Routes, Navigate} from 'react-router-dom'
function App() {

  return (
    <Router>
      <Routes>
        <Route exact path="/portfolio"        element={<JosephHome></JosephHome>}>  	</Route>
        <Route exact path="/portfolio/project" element={<ProjectPage></ProjectPage>}>	</Route>
      </Routes>
    </Router>
  );
}

export default App;
