
import './ProjectPage.css'
import Projects from '../../../Data/Joseph/projects.json'
import JosephHeader from "../Header/JosephHeader";
import Container from 'react-bootstrap/Container'
import {useNavigate } from 'react-router-dom'
import JosephNavbar from "../Navbar/JosephNavbar";
import React, { useEffect, useState } from 'react';
import JosephFooter from '../Footer/JosephFooter';
import { marked } from 'marked';


const ProjectPage = (props) => {
    let currentUrl = new URL(window.location.href)
    let thisProject = Projects[currentUrl.searchParams.get('name')]
    let navigate = useNavigate()
    var examplePicture = thisProject.gif ? thisProject.gif : thisProject.image
    var exampleMedia = thisProject.video ? <video autoPlay muted loop> <source  src={'.' + thisProject.video} type="video/mp4" /></video> : <img src={'.' + examplePicture} alt="" />
    const [aboutText, setaboutText] = useState('');
    const [technologyText, settechnologyText] = useState('');
    const [challengesText, setchallengesText] = useState('');
    const [solutionText, setsolutionText] = useState('');
    const [featuresText, setfeaturesText] = useState('');
    const [readmeText, setReadMeText] = useState('');

    function showPicture(ev) {
        var element = document.getElementsByClassName('projectPictureBox')[0]
        element.classList.add('active')
    }

    function hidePicture(ev) {
        ev.target.classList.remove('active')
    }

    function getReadMe() {
        fetch(thisProject.readme)
        .then(res => {
            if (res.ok) {
                return res.text()
            }
            throw res
        })
        .then(data => {
            setReadMeText(marked.parse(data))
        })
        .catch(error => {
            console.log(error)
        })
    }
      
    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = "Joseph's Portfolio - " + thisProject.name
        if (thisProject.readme) {
            getReadMe()
        }
    }, [])

    return (
        <div className='ProjectPage'>
            <JosephHeader clickFunction={showPicture} picture={'.' + thisProject.image} title={thisProject.name} isProject={true}></JosephHeader>
            <JosephNavbar buttons={['Technology','Usage','Features']}></JosephNavbar>
            <Container fluid  onClick={hidePicture} className="projectPictureBox">
                <p> X </p>
                {exampleMedia}
            </Container>
            
            <button className="goHome" onClick={() => navigate("/portfolio")}></button>
            <Container fluid className='contentContainer'>
            { thisProject.readme && <a href={thisProject.github}>
                <div className='view-on-github'>
                    <img src='../images/github.svg'></img>
                    <p>View on Github</p>
                </div>
            </a>
            }
            <Container className="readMe">
            <div dangerouslySetInnerHTML={{__html: readmeText}}>

            </div>
            </Container>
            </Container>
            <JosephFooter></JosephFooter>
        </div>
    )
}

export default ProjectPage