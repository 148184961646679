import JosephHeader from "../Header/JosephHeader";
import JosephContent from "../Content/JosephContent";
import React from 'react';
import JosephFooter from "../Footer/JosephFooter";
import { useEffect, useState } from 'react';


const JosephHome = () => {

    const [title, setTitle] = useState("");
    const [content, setContent] = useState();
    const [footer, setFooter] = useState();
    useEffect(() => {
        document.title = "Joseph's Portfolio"
        if (window === window.top) {
                setTitle("Joseph's Portfolio")
                setContent(<JosephContent></JosephContent>)
                setFooter(<JosephFooter></JosephFooter>)
            } else {
                document.getElementsByTagName("body")[0].style = "background-color: rgb(189, 41, 10)";
                document.getElementsByClassName("JosephHeader")[0].style = "box-shadow: unset";
                document.getElementsByClassName("header-picture-box")[0].style.setProperty("margin","auto");
            }
    },[])

    return (
        <div className="JosephHome">
                <JosephHeader picture="./images/joseph/me.webp" title={title}></JosephHeader>
                {content}
                {footer}
        </div>
    )
}

export default JosephHome;