import Socials from '../../../Data/Joseph/socials.json'
import './Social.css'
const Social = (props) => {
    let nameRef = props.nameRef
    let thisSocial = Socials[nameRef]

    return (
        <div className="Social">
            <a aria-label={thisSocial.name} rel="noreferrer" target="_blank" href={thisSocial.link}>
                <img src={"." + thisSocial.image} alt={thisSocial.name} />
            </a>
        </div>
    )
}

export default Social