import './ExperienceCard.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Experience from '../../../Data/Joseph/experience.json'

const ExperienceCard = (props) => {
    let thisExperience = Experience[props.typeRef][props.nameRef]

    let picture = thisExperience.picture
    let position = thisExperience.position
    let company = thisExperience.company
    let startDate = thisExperience.startDate
    let endDate = thisExperience.endDate
    let location = thisExperience.location
    let hasEndDate = endDate != ""

    return(
        <div className="ExperienceCard">
            <Container>
                <Row>
                <Col className="experiencePicture" sm={3}>
                
                <img alt={company} src={picture}></img>
                </Col>
                <Col sm={9}>
                <Row><h1>{position}</h1></Row>
                <Row><h2>{company}</h2></Row>
                {hasEndDate ? (
<Row><h3>{startDate} - {endDate}</h3></Row>
):(<Row><h3>{startDate}</h3></Row>)}
                <Row><h3>{location}</h3></Row>
                </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ExperienceCard
