import './JosephHeader.css'

const JosephHeader = (props) => {
    let title = props.title
    let picture = props.picture
    let clickFunction = props.clickFunction
    let isProject = props.isProject
    let clickHere
    if (isProject) {
        clickHere = <div className='click-here'><img src="../images/tap.png" alt=""/> <p>Click here!</p></div>
    }
    
    return (
        <div className="JosephHeader">

            <h1>{title}</h1>

            <div className="ocean">
                <div className="wave"></div>
                <div className="wave"></div>
            </div>
            
            <div className="rug-shadow"></div>

            <div className="rug"></div>

            <div className="header-picture-box-holder">
                <div onClick={clickFunction}  style={{backgroundImage:'url('+ picture +')'}} className="header-picture-box">
                    {clickHere}
                </div>
            </div>
        </div>
    )
}

export default JosephHeader