import { Link } from 'react-router-dom'
import './Project.css'
import Container from 'react-bootstrap/Container'
import Projects from '../../../Data/Joseph/projects.json'

const Project = (props) => {
    let thisProject = Projects[props.nameRef]
    let title = thisProject.name
    let date = thisProject.date
    let status = thisProject.status
    let tools = thisProject.tools
    let nameRef = props.nameRef
    
    var toolElements = []
    var i = 0
    tools.forEach( (tool)=> {
        var element = <p key={i} className="Tag">{tool}</p>
        toolElements.push(element)
        i += 1
    })
    return (
        <Link to={"/portfolio/project?name=" + nameRef}>
        <div className="Project">                      
            <div className="projectHeaderBox">
                <div style={{display: "flex", justifyContent: 'center'}}>
                    <h1>{title} </h1>
                    <h2>~{date}</h2>
                </div>
                <div className="bleedingBackground"></div>
            </div>
            <img alt={title} src={thisProject.image}></img>
            <p> View Project <i><img className="arrowIcon" src="./images/arrowicon.png" alt="" /></i></p>
            <Container fluid className="tagBar">
                {toolElements}
            </Container>
        </div>
        </Link>

    )
}

export default Project
